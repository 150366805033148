import type { FloatingClasses } from "@t/client/theme/Floating";
import { tw } from "../noop";

const floatingClasses: FloatingClasses = {
    colors: {
        dark: tw("bg-gray-900 text-white dark:bg-gray-700"),
        light: tw("border border-gray-200 bg-white text-gray-900"),
        auto: tw(
            "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
        ),
    },
    arrow: {
        dark: tw("bg-gray-900 dark:bg-gray-700"),
        auto: tw("bg-white dark:bg-gray-700"),
        light: tw("bg-white"),
    },
};

export { floatingClasses };
