import type { ToastClasses } from "@t/client/theme/Toast";
import { tw } from "../noop";

// Header text inside toasts
const toastClasses: ToastClasses = {
    default: tw("text-gray-900 dark:text-gray-300"),
    info: tw("text-blue-500 dark:text-blue-600"),
    error: tw("text-red-500 dark:text-red-500"),
    warning: tw("text-yellow-500 dark:text-yellow-600"),
    success: tw("text-green-600 dark:text-green-500"),
};

export { toastClasses };
