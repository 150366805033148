import clsx from "clsx";
import type { ReactNode } from "react";
import type { ToastContentProps } from "react-toastify";
import { useTheme } from "@contexts/ThemeClassesContext";

type ErrorProps = ToastContentProps<unknown> & {
    title?: string;
    message?: ReactNode;
};

export const ToastWrapper = ({
    title = "Alert",
    message = "",
    ...rest
}: ErrorProps) => {
    const { themeClasses: ThemeClasses } = useTheme();
    return (
        <span>
            <h3
                className={clsx(
                    ThemeClasses.toast[rest.toastProps.type],
                    "text-lg font-semibold"
                )}
            >
                {title}
            </h3>
            {message}
        </span>
    );
};
