import type { AccordionTheme } from "@t/client/theme/Accordion";
import { tw } from "../noop";

export const accordionTheme: AccordionTheme = {
    body: {
        base: tw(
            "divide-y divide-gray-200 border-gray-200 dark:divide-gray-600 dark:border-gray-600",
        ),
        flush: {
            off: tw("rounded-lg border"),
            on: tw("border-b"),
        },
    },
    content: {
        base: tw("px-5 py-5 first:rounded-t-lg last:rounded-b-lg"),
    },
    title: {
        arrow: {
            base: tw("h-6 w-6 shrink-0"),
            open: {
                off: tw(""),
                on: tw("rotate-180"),
            },
        },
        base: tw(
            "flex w-full items-center justify-between px-5 py-5 text-left font-medium text-gray-700 first:rounded-t-lg last:rounded-b-lg dark:text-gray-300",
            "transition-colors duration-500",
        ),
        flush: {
            off: tw("hover:bg-gray-100 dark:hover:bg-gray-700"),
            on: tw("!bg-transparent dark:!bg-transparent"),
        },
        heading: tw(""),
        open: {
            off: tw(""),
            on: tw("bg-gray-100 font-semibold dark:bg-gray-700"),
        },
    },
};
