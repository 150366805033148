import type { ButtonGroupTheme, ButtonTheme } from "@t/client/theme/Button";
import { tw } from "../noop";

const buttonGroupClasses: ButtonGroupTheme = {
    position: {
        none: tw("focus:!ring-2"),
        start: tw("rounded-r-none"),
        middle: tw("!rounded-none border-l-0 pl-0"),
        end: tw("rounded-l-none border-l-0 pl-0"),
    },
};

const buttonClasses: ButtonTheme = {
    color: {
        dark: tw(
            "border border-transparent bg-gray-800 text-white hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 disabled:hover:bg-gray-800 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-800 dark:disabled:hover:bg-gray-800",
        ),
        failure: tw(
            "border border-transparent bg-red-700 text-white hover:bg-red-800 focus:ring-4 focus:ring-red-300 disabled:hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 dark:disabled:hover:bg-red-600",
        ),
        gray: tw(
            "border border-gray-300 bg-white text-gray-900 ring-orange-primary hover:bg-gray-100 hover:text-gray-700 focus:text-gray-700 disabled:hover:bg-white dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
        ),
        info: tw(
            "border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600",
        ),
        light: tw(
            "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:border-gray-600 dark:bg-gray-600 dark:text-white dark:hover:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-700",
        ),
        purple: tw(
            "border border-transparent bg-purple-700 text-white hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 disabled:hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 dark:disabled:hover:bg-purple-600",
        ),
        success: tw(
            "border border-transparent bg-green-700 text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 disabled:hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 dark:disabled:hover:bg-green-600",
        ),
        warning: tw(
            "border border-transparent bg-yellow-400 text-yellow-900 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 disabled:hover:bg-yellow-400 dark:focus:ring-yellow-900 dark:disabled:hover:bg-yellow-400",
        ),
        blue: tw(
            "border border-blue-300 bg-white text-blue-900 hover:bg-blue-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:border-blue-600 dark:bg-blue-600 dark:text-white dark:hover:border-blue-700 dark:hover:bg-blue-700 dark:focus:ring-blue-700",
        ),
        cyan: tw(
            "border border-cyan-300 bg-white text-cyan-900 hover:bg-cyan-100 focus:ring-4 focus:ring-cyan-300 disabled:hover:bg-white dark:border-cyan-600 dark:bg-cyan-600 dark:text-white dark:hover:border-cyan-700 dark:hover:bg-cyan-700 dark:focus:ring-cyan-700",
        ),
        green: tw(
            "border border-green-300 bg-white text-green-900 hover:bg-green-100 focus:ring-4 focus:ring-green-300 disabled:hover:bg-white dark:border-green-600 dark:bg-green-600 dark:text-white dark:hover:border-green-700 dark:hover:bg-green-700 dark:focus:ring-green-700",
        ),
        indigo: tw(
            "border border-indigo-300 bg-white text-indigo-900 hover:bg-indigo-100 focus:ring-4 focus:ring-indigo-300 disabled:hover:bg-white dark:border-indigo-600 dark:bg-indigo-600 dark:text-white dark:hover:border-indigo-700 dark:hover:bg-indigo-700 dark:focus:ring-indigo-700",
        ),
        lime: tw(
            "border border-lime-300 bg-white text-lime-900 hover:bg-lime-100 focus:ring-4 focus:ring-lime-300 disabled:hover:bg-white dark:border-lime-600 dark:bg-lime-600 dark:text-white dark:hover:border-lime-700 dark:hover:bg-lime-700 dark:focus:ring-lime-700",
        ),
        pink: tw(
            "border border-pink-300 bg-white text-pink-900 hover:bg-pink-100 focus:ring-4 focus:ring-pink-300 disabled:hover:bg-white dark:border-pink-600 dark:bg-pink-600 dark:text-white dark:hover:border-pink-700 dark:hover:bg-pink-700 dark:focus:ring-pink-700",
        ),
        red: tw(
            "border border-red-300 bg-white text-red-900 hover:bg-red-100 focus:ring-4 focus:ring-red-300 disabled:hover:bg-white dark:border-red-600 dark:bg-red-600 dark:text-white dark:hover:border-red-700 dark:hover:bg-red-700 dark:focus:ring-red-700",
        ),
        teal: tw(
            "border border-teal-300 bg-white text-teal-900 hover:bg-teal-100 focus:ring-4 focus:ring-teal-300 disabled:hover:bg-white dark:border-teal-600 dark:bg-teal-600 dark:text-white dark:hover:border-teal-700 dark:hover:bg-teal-700 dark:focus:ring-teal-700",
        ),
        yellow: tw(
            "border border-yellow-300 bg-white text-yellow-900 hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-300 disabled:hover:bg-white dark:border-yellow-600 dark:bg-yellow-600 dark:text-white dark:hover:border-yellow-700 dark:hover:bg-yellow-700 dark:focus:ring-yellow-700",
        ),
        orange: tw(
            "border border-orange-300 bg-white text-orange-800 hover:bg-orange-100 focus:ring-4 focus:ring-orange-300 disabled:hover:bg-white dark:border-orange-300 dark:bg-orange-primary dark:text-black dark:hover:border-orange-700 dark:hover:bg-orange-700 dark:focus:ring-orange-700",
        ),
        default: tw(
            "border border-orange-300 bg-white text-orange-800 hover:bg-orange-100 focus:ring-4 focus:ring-orange-300 disabled:hover:bg-white dark:border-orange-300 dark:bg-orange-primary dark:text-black dark:hover:border-orange-700 dark:hover:bg-orange-700 dark:focus:ring-orange-700",
        ),
    },
    gradient: {
        cyan: tw(
            "bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800",
        ),
        failure: tw(
            "bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800",
        ),
        info: tw(
            "bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 ",
        ),
        lime: tw(
            "bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 text-gray-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800",
        ),

        pink: tw(
            "bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-pink-300 dark:focus:ring-pink-800",
        ),
        purple: tw(
            "bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800",
        ),
        success: tw(
            "bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800",
        ),
        teal: tw(
            "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800",
        ),
        default: tw(""),
    },
    gradientDuoTone: {
        cyanToBlue: tw(
            "bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800",
        ),
        greenToBlue: tw(
            "bg-gradient-to-br from-green-400 to-blue-600 text-white hover:bg-gradient-to-bl focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800",
        ),
        pinkToOrange: tw(
            "bg-gradient-to-br from-pink-500 to-orange-primary text-white hover:bg-gradient-to-bl focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800",
        ),
        purpleToBlue: tw(
            "bg-gradient-to-br from-purple-600 to-blue-500 text-white hover:bg-gradient-to-bl focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800",
        ),
        purpleToPink: tw(
            "bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:bg-gradient-to-l focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800",
        ),
        redToYellow: tw(
            "bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 text-gray-900 hover:bg-gradient-to-bl focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400",
        ),
        tealToLime: tw(
            "bg-gradient-to-r from-teal-200 to-lime-200 text-gray-900 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 hover:!text-gray-900 focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700",
        ),
    },
    inner: {
        position: {
            none: tw(""),
            start: tw("rounded-r-none"),
            middle: tw("!rounded-none"),
            end: tw("rounded-l-none"),
        },
        outline: tw("border border-transparent"),
    },
    outline: {
        color: {
            gray: tw("border border-gray-900 dark:border-white"),
            default: tw("border"),
            light: tw(""),
        },
        off: tw(""),
        on: tw(
            "flex w-full justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit dark:bg-gray-900 dark:text-white",
        ),
        pill: {
            off: tw("rounded-md"),
            on: tw("rounded-full"),
        },
    },
    pill: {
        off: tw("rounded-lg"),
        on: tw("rounded-full"),
    },
    size: {
        xs: tw("px-2 py-1 text-xs"),
        sm: tw("px-3 py-1.5 text-sm"),
        md: tw("px-4 py-2 text-sm"),
        lg: tw("px-5 py-2.5 text-base"),
        xl: tw("px-6 py-3 text-base"),
    },
};

export { buttonClasses, buttonGroupClasses };
