import type { SelectClasses } from "@t/client";
import { tw } from "@utils/constants";
const selectDropdownClasses: SelectClasses = {
    sizes: {
        sm: tw("p-2 sm:text-xs"),
        md: tw("p-2.5 text-sm"),
        lg: tw("sm:text-md p-4"),
    },
    colors: {
        default: tw(
            "border-gray-300 bg-gray-50 text-gray-900 focus:border-orange-primary focus:ring-orange-primary dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-orange-primary dark:focus:ring-orange-primary",
        ),
        info: tw(
            "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        ),
        failure: tw(
            "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        ),
        warning: tw(
            "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        ),
        success: tw(
            "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500",
        ),
    },
};

export { selectDropdownClasses };
