import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.css";

import AxiosProvider from "@contexts/AuthContext";
import { ThemeClassContextComponent } from "@contexts/ThemeClassesContext";
import Theme from "@contexts/ThemeContext";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <HelmetProvider>
            <AxiosProvider>
                <ThemeClassContextComponent>
                    <Theme>
                        <App />
                    </Theme>
                </ThemeClassContextComponent>
            </AxiosProvider>
        </HelmetProvider>
    </React.StrictMode>,
);
