import { ToastWrapper } from "@components/Toast/ToastWrapper";
import type { ApiErrorResponse } from "@t/server";
import { isAxiosError, isCancel } from "axios";
import type { ReactNode } from "react";
import type {
    Id,
    ToastContent,
    ToastContentProps,
    TypeOptions,
    UpdateOptions,
} from "react-toastify";
import { toast } from "react-toastify";
import { extractApiError } from "./axios";

export function updateToastFromLoading(
    id: Id,
    message: ToastContent<unknown>,
    type?: TypeOptions,
    options: Omit<UpdateOptions<unknown>, "type" | "render"> = {
        autoClose: 5000,
        closeButton: true,
    },
) {
    toast.update(id, {
        render: message,
        type: type,
        isLoading: false,
        ...options,
    });
}

export const generateToastWrapper: (
    title: string,
    message?: ReactNode,
) => (props: ToastContentProps<unknown>) => React.ReactNode = (
    title,
    message,
) => {
    // eslint-disable-next-line react/display-name
    return (props) => (
        <ToastWrapper title={title} message={message} {...props} />
    );
};

/**
 * Generate a toast wrapper when the error is inside the toast props.
 * Usually used with toast.promise calls as the data will be the error.
 * @param title Title of the toast
 * @returns
 */
export const generateErrorToastWrapper: (
    title: string,
) => (props: ToastContentProps<unknown>) => React.ReactNode = (title) => {
    // eslint-disable-next-line react/display-name
    return (props: ToastContentProps<unknown>) => {
        const { data } = props;

        if (isAxiosError<ApiErrorResponse>(data) && !isCancel(data)) {
            return (
                <ToastWrapper
                    title={title}
                    message={extractApiError(data)}
                    {...props}
                />
            );
        }

        return <ToastWrapper title={title} {...props} />;
    };
};

export function delayDismiss(id: Id, timeout = 750) {
    setTimeout(() => toast.dismiss(id), timeout);
}
