import type { ModalTheme } from "@t/client";
import type { ModalSizes } from "@t/client/Modal";
import type { Positions } from "@t/client/Positions";
import { tw } from "../noop";

const modalPositions: Positions = {
    "top-left": tw("items-start justify-start"),
    "top-center": tw("items-start justify-center"),
    "top-right": tw("items-start justify-end"),

    "center-left": tw("items-center justify-start"),
    center: tw("items-center justify-center"),
    "center-right": tw("items-center justify-end"),

    "bottom-right": tw("items-end justify-end"),
    "bottom-center": tw("items-end justify-center"),
    "bottom-left": tw("items-end justify-start"),
};

const modalSizes: ModalSizes = {
    sm: tw("max-w-sm"),
    md: tw("max-w-md"),
    lg: tw("max-w-lg"),
    xl: tw("max-w-xl"),
    "2xl": tw("max-w-2xl"),
    "3xl": tw("max-w-3xl"),
    "4xl": tw("max-w-4xl"),
    "5xl": tw("max-w-5xl"),
    "6xl": tw("max-w-6xl"),
    "7xl": tw("max-w-7xl"),
};

const modalTheme: ModalTheme = {
    positions: modalPositions,
    sizes: modalSizes,
};

export { modalTheme, modalPositions, modalSizes };
