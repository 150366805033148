import type { ApiErrorResponse } from "@t/server";
import type { AxiosError } from "axios";
import type { ReactNode } from "react";

export const extractApiError = (e: AxiosError<ApiErrorResponse>): ReactNode => (
    <ul>
        {extractApiErrorsFromResponse(e).map((e, i) => (
            <li key={i}>
                {e}
                <br />
            </li>
        ))}
    </ul>
);

const extractApiErrorsFromResponse = (
    e: AxiosError<ApiErrorResponse>,
): string[] => {
    if (e.response?.data.errors) {
        return (
            Object.values(e.response?.data.errors)
                .flat()
                // Remove duplicates
                .filter((v, i, a) => a.indexOf(v) === i)
        );
        //return Object.values(e.response?.data.errors).flat().join(" ");
    }
    return [
        e.response?.data.detail ||
            e.response?.statusText ||
            "Undefined error occured",
    ];
};
