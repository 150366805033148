import { getLocalStorage, setLocalStorage } from "@utils/storage";
import type { ReactNode } from "react";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

interface ThemeContextProps {
    children?: ReactNode;
}

type Themes = "light" | "dark";

interface ThemeContextType {
    isLight: boolean;
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);
export const useThemeContext = () => useContext(ThemeContext);

/*
 * Get the user's preferred color scheme
 * If they have a preference stored in localStorage, use that
 * If they don't have a preference stored in localStorage, use their OS preference
 * If they don't have a preference stored in localStorage and their OS doesn't have a preference, use light
 * setLocalStorage has been commented out because we don't want to set the user's preference until they explicitly choose it
 * This is useful when users are using auto dark mode and don't want to be forced into a theme
 */
export const getPreferColor = (): Themes => {
    const stored: null | string = getLocalStorage("theme", null);

    if (stored) return stored as Themes;

    if (!window.matchMedia) {
        //setLocalStorage("theme", "light");
        return "light";
    }

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        //setLocalStorage("theme", "dark");
        return "dark";
    }

    //setLocalStorage("theme", "light");
    return "light";
};

const Theme = ({ children }: ThemeContextProps) => {
    const [theme, setTheme] = useState<Themes>(getPreferColor());

    const isLight = theme === "light";

    document.documentElement.classList.toggle("dark", !isLight);
    document.documentElement.style.colorScheme = theme;

    document.querySelectorAll("[data-agrid-container]").forEach((el) => {
        el.classList.toggle("ag-theme-alpine-dark", !isLight);
        el.classList.toggle("ag-theme-alpine", isLight);
    });

    // Event handler to change theme automatically when OS preference changes
    useEffect(() => {
        // Don't set up event listener if browser doesn't support matchMedia
        if (!window.matchMedia) return;

        // Don't set up event listener if user has already chosen a theme
        if (getLocalStorage("theme", null) !== null) return;

        const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

        const handleChange = () => {
            setTheme(matchMedia.matches ? "dark" : "light");
        };

        matchMedia.addEventListener("change", handleChange);

        return () => {
            matchMedia.removeEventListener("change", handleChange);
        };
    }, []);

    const toggleTheme = useCallback(() => {
        setTheme((prev) => {
            const newTheme = prev === "light" ? "dark" : "light";
            setLocalStorage("theme", newTheme);
            return newTheme;
        });
    }, []);

    return (
        <ThemeContext.Provider value={{ isLight, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default Theme;
