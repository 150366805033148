import FullPageLoader from "@components/Loading/FullPageLoader";
import AppRoutes from "@routes/routes";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { ToastContainer, type CloseButtonProps } from "react-toastify";

const ToastClose = ({ closeToast }: CloseButtonProps) => (
    <button
        className="cursor-pointer self-start border-none bg-transparent p-0 text-black opacity-75 outline-none transition-all transition-none hover:opacity-100 dark:text-white"
        type="button"
        aria-label="close"
        onClick={closeToast}
    >
        <svg
            className="block h-4 w-4 fill-current"
            aria-hidden="true"
            viewBox="0 0 14 16"
        >
            <path
                fillRule="evenodd"
                d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
            ></path>
        </svg>
    </button>
);

export type EventOptions = {
    /**
     * Callback called when the event is successfully sent.
     */
    readonly callback?: () => void;
    /**
     * Properties to be bound to the event.
     */
    readonly props?: { readonly [propName: string]: string | number | boolean };
};

const App = () => {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                    {
                      "@context": "https://schema.org/",
                      "@graph": [
                        {
                          "@type": "Organization",
                          "name": "GING Systems",
                          "legalName": "GING Systems LTD",
                          "description": "GING Systems is a not for profit, limited by guarantee organisation created with and for the packaging value chain.",
                          "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Office 1 Brunswick House, Brunswick Way",
                            "addressLocality": "Liverpool",
                            "addressRegion": "England",
                            "postalCode": "L3 4BN",
                            "addressCountry": "GB"
                          },
                          "email": "info@gingsystem.org",
                          "slogan": "Packaging Data Meets Innovation.",
                          "telephone": "0161 327 2755",
                          "url": "https://gingsystem.org/",
                          "logo": "https://cdn.gingsystem.org/files/GingLogo.svg",
                          "foundingDate": "2023-10-09",
                          "owns": {
                            "@type": "SoftwareApplication",
                            "name": "GING",
                            "description": "GING is designed to bring together data on packaging data for the entire packaging value chain.",
                            "isAccessibleForFree": "False",
                            "url": "https://gingsystem.org/",
                            "operatingSystem": "Windows, Linux, Mac",
                            "applicationCategory": "BusinessApplication",
                            "applicationSubCategory": "Supply Chain Management",
                            "permissions": "Requires full internet access",
                            "countryOfOrigin": "United Kingdom",
                            "audience": {
                              "@type": "audience",
                              "audienceType": "Organisations involved in the packaging value chain"
                            },
                            "funder": {
                              "@type": "GovernmentOrganization",
                              "name": "Innovate UK",
                              "legalName": "Innovate UK",
                              "url": "https://www.ukri.org/councils/innovate-uk/",
                              "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Caxton House, Tothill Street",
                                "addressLocality": "London",
                                "addressRegion": "England",
                                "postalCode": "SW1H 9NA",
                                "addressCountry": "GB"
                              },
                              "email": "info@ukri.org",
                              "slogan": "We help UK businesses to grow through innovation.",
                              "telephone": "0300 321 4357",
                              "logo": "https://www.ukri.org/wp-content/themes/ukri/assets/img/ukri-innovate-uk-standard-logo.png"
                            },
                            "copyrightHolder": {
                              "@type": "Organization",
                              "name": "GING Systems",
                              "legalName": "GING Systems LTD"
                            },
                            "contributor": {
                              "@type": "Organization",
                              "name": "Dsposal",
                              "legalName": "Dsposal LTD",
                              "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Office 1 Brunswick House, Brunswick Way",
                                "addressLocality": "Liverpool",
                                "addressRegion": "England",
                                "postalCode": "L3 4BN",
                                "addressCountry": "GB"
                              },
                              "email": "always@yourdsposal.uk",
                              "slogan": "Waste is complicated, Dsposal isn't.",
                              "telephone": "0161 327 2755",
                              "url": "https://dsposal.uk",
                              "vatID": "248 4664 74",
                              "logo": "https://dsposal.uk/assets-dsposal/img/dsposal/brand/dsposal-logo-light.svg"
                            }
                          }
                        }
                      ]
                    }
                    `}
                </script>
            </Helmet>
            <Suspense fallback={<FullPageLoader />}>
                <RouterProvider router={AppRoutes} />
            </Suspense>
            <ToastContainer
                toastClassName="relative z-0 mb-4 box-border flex max-h-[800px] min-h-[64px] cursor-pointer justify-between overflow-hidden rounded-md border border-gray-300 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800"
                bodyClassName="mx-0 my-auto flex flex-auto items-center p-2 text-black dark:text-white"
                position={"top-center"}
                closeButton={ToastClose}
                autoClose={5000}
                newestOnTop={true}
                draggable
                pauseOnHover
                pauseOnFocusLoss
            />
        </>
    );
};

export default App;
