import type { FieldClasses } from "@t/client/theme/Fields";
import { tw } from "../noop";

const fieldClasses: FieldClasses = {
    colors: {
        info: tw(
            "border-blue-500 bg-blue-50 text-blue-900 placeholder-blue-700 autofill:shadow-blue-100 focus:border-blue-500 focus:ring-blue-500 dark:border-blue-400 dark:bg-gray-700 dark:text-white dark:placeholder-blue-300 dark:autofill:shadow-gray-600 dark:focus:border-blue-500 dark:focus:ring-blue-500",
        ),
        failure: tw(
            "border-red-500 bg-red-50 text-red-900 placeholder-red-700 autofill:shadow-red-100 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-gray-700 dark:text-white dark:placeholder-red-400 dark:autofill:shadow-gray-600 dark:focus:border-red-500 dark:focus:ring-red-500",
        ),
        warning: tw(
            "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 autofill:shadow-yellow-100 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-gray-700 dark:text-white dark:placeholder-yellow-400 dark:autofill:shadow-gray-600 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        ),
        success: tw(
            "border-green-500 bg-green-50 text-green-900 placeholder-green-700 autofill:shadow-green-100 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-gray-700 dark:text-white dark:placeholder-green-400 dark:autofill:shadow-gray-600 dark:focus:border-green-500 dark:focus:ring-green-500",
        ),
        default: tw(
            "border-gray-300 bg-gray-50 text-gray-900 autofill:shadow-gray-200 focus:border-orange-primary focus:ring-orange-primary dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:autofill:shadow-gray-600 dark:focus:border-orange-primary dark:focus:ring-orange-primary",
        ),
    },
    sizes: {
        sm: tw("p-2 sm:text-xs"),
        md: tw("p-2.5 text-sm"),
        lg: tw("sm:text-md p-4"),
    },
    checkSizes: {
        sm: tw("h-3 w-3"),
        md: tw("h-4 w-4"),
        lg: tw("h-6 w-6"),
    },
    checkColors: {
        info: tw(
            "border-blue-500 text-blue-500 focus:border-blue-500 focus:ring-blue-500 dark:border-blue-400 dark:focus:border-blue-500 dark:focus:ring-blue-500",
        ),
        failure: tw(
            "border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:focus:border-red-500 dark:focus:ring-red-500",
        ),
        warning: tw(
            "border-yellow-500 text-yellow-500 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        ),
        success: tw(
            "border-green-500 text-green-500 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:focus:border-green-500 dark:focus:ring-green-500",
        ),
        // Originally text-blue-500
        default: tw(
            "border-gray-300 text-orange-primary focus:ring-orange-primary dark:border-gray-600 dark:focus:ring-orange-primary",
        ),
    },
};

export { fieldClasses };
