import { useAuthContext } from "@contexts/AuthContext";
import { Navigate, useLocation } from "react-router";

export type ProtectedRouteProps = {
    redirectPath: string;
    outlet: JSX.Element;
};

const AuthWrapper = ({ redirectPath, outlet }: ProtectedRouteProps) => {
    const { loggedIn } = useAuthContext();
    const currentLocation = useLocation();
    if (loggedIn) {
        return outlet;
    } else {
        return (
            <Navigate
                to={{
                    pathname: redirectPath,
                }}
                state={{
                    from: currentLocation.pathname,
                }}
            />
        );
    }
};

export default AuthWrapper;
