import type { RadioGroupClasses } from "@t/client";
import { tw } from "@utils/constants";

const radioGroupClasses: RadioGroupClasses = {
    options: {
        colours: {
            info: tw(
                "ui-checked:border-blue-500 ui-active:border-blue-500 ui-active:ring-blue-500 dark:ui-checked:border-blue-400 dark:ui-active:border-blue-400 dark:ui-active:ring-blue-400",
            ),
            failure: tw(
                "ui-checked:border-red-500 ui-active:border-red-500 ui-active:ring-red-500 ui-checked:dark:border-red-400 ui-active:dark:border-red-400 ui-active:dark:focus:ring-red-500",
            ),
            warning: tw(
                "ui-checked:border-yellow-500 ui-active:border-yellow-500 ui-active:ring-yellow-500 ui-checked:dark:border-yellow-400 ui-active:dark:border-yellow-400 ui-active:dark:focus:ring-yellow-500",
            ),
            success: tw(
                "ui-checked:border-green-500 ui-active:border-green-500 ui-active:ring-green-500 ui-checked:dark:border-green-400 ui-active:dark:border-green-400 ui-active:dark:focus:ring-green-500",
            ),
            default: tw(
                "ui-checked:border-orange-primary ui-active:border-orange-primary ui-active:ring-orange-primary ui-checked:dark:border-orange-primary",
            ),
        },
    },
    icon: {
        info: tw("text-blue-500 dark:text-blue-400"),
        failure: tw("text-red-500 dark:text-red-400"),
        warning: tw("text-yellow-500 dark:text-yellow-400"),
        success: tw("text-green-500 dark:text-green-400"),
        default: tw("text-orange-primary dark:text-orange-primary"),
    },
};

export { radioGroupClasses };
