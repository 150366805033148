import { Spinner } from "@components/Spinner/Spinner";
import { useEffect, useState } from "react";

const FullPageLoader = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <>
            {show && (
                <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-white opacity-75 dark:bg-gray-800">
                    <Spinner size="xl" />
                    <h2 className="text-center text-xl font-semibold text-gray-900 dark:text-gray-50">
                        Loading...
                    </h2>
                    <p className="w-1/3 text-center  text-gray-900 dark:text-gray-50">
                        This may take a few seconds, please do not close this
                        page.
                    </p>
                </div>
            )}
        </>
    );
};

export default FullPageLoader;
