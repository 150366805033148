export function replaceLastSegmentWith(
    source: string,
    replacement: string,
    segmentSplit = ".",
): string {
    if (!source) throw new Error("source argument must not be falsy");
    if (!replacement) throw new Error("replacement argument must not be falsy");

    const lastIndex = source.lastIndexOf(segmentSplit);
    if (lastIndex >= 0) {
        return source.substring(0, lastIndex + 1) + replacement;
    } else {
        return replacement;
    }
}

/**
 * This function transforms a given ID into a safe ID by converting it to lowercase
 * and replacing all non-alphanumeric, non-underscore, or non-hyphen characters with a hyphen.
 *
 * @param id - This is the initial unprocessed ID.
 * @returns returns the transformed "safe" ID.
 */
export const safeId = (id: string): string =>
    id.toLowerCase().replace(/[^a-z0-9-_]/g, "-");
