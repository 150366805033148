import type { BadgeTheme } from "@t/client/theme/Badge";
import { tw } from "../noop";

const badgeClasses: BadgeTheme = {
    default: tw(
        "border-gray-800 bg-gray-100 text-gray-800 group-hover:bg-gray-200 dark:border-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:group-hover:bg-gray-600"
    ),
    info: tw(
        "border-blue-800 bg-blue-100 text-blue-800 group-hover:bg-blue-200 dark:bg-blue-200 dark:text-blue-800 dark:group-hover:bg-blue-300"
    ),
    failure: tw(
        "border-red-800 bg-red-100 text-red-800 group-hover:bg-red-200 dark:border-red-900 dark:bg-red-200 dark:text-red-900 dark:group-hover:bg-red-300"
    ),
    warning: tw(
        "border-yellow-800 bg-yellow-100 text-yellow-800 group-hover:bg-yellow-200 dark:border-yellow-900 dark:bg-yellow-200 dark:text-yellow-900 dark:group-hover:bg-yellow-300"
    ),
    success: tw(
        "border-green-800 bg-green-100 text-green-800 group-hover:bg-green-200 dark:border-green-900 dark:bg-green-200 dark:text-green-900 dark:group-hover:bg-green-300"
    ),
};

export { badgeClasses };
