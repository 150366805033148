import type { LabelClasses } from "@t/client/theme/Fields";
import { tw } from "../noop";

const labelClasses: LabelClasses = {
    default: tw("text-gray-900 dark:text-gray-300"),
    info: tw("text-blue-600 dark:text-blue-500"),
    failure: tw("text-red-700 dark:text-red-500"),
    warning: tw("text-yellow-500 dark:text-yellow-600"),
    success: tw("text-green-600 dark:text-green-500"),
};

export { labelClasses };
