import type { HelperColors } from "@t/client/Fields";
import { tw } from "../noop";

const helperTextClasses: HelperColors = {
    info: tw("text-blue-700 dark:text-blue-800"),
    success: tw("text-green-600 dark:text-green-500"),
    failure: tw("text-red-600 dark:text-red-500"),
    warning: tw("text-yellow-500 dark:text-yellow-600"),
    default: tw("text-gray-500 dark:text-gray-400"),
};

const utilClasses = {
    yDivider: tw("divide-zinc-10 divide-y-2 dark:divide-zinc-100/5"),
};

export { helperTextClasses, utilClasses };
