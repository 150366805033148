import { useRef } from "react";

export const useAbortController = () => {
    const abortController = useRef<AbortController>();

    const getAbortController = () => {
        if (!abortController.current) {
            abortController.current = new AbortController();
        }
        return abortController.current;
    };

    const resetAbortController = () => {
        abortController.current?.abort();
        abortController.current = new AbortController();
    };

    return { getAbortController, resetAbortController };
};
