import type { ProtectedRouteProps } from "@components/Routes/AuthWrapper";
import { useAuthContext } from "@contexts/AuthContext";
import { Navigate } from "react-router";

type PermissionProtectedRouteProps = Omit<
    ProtectedRouteProps,
    "redirectPath"
> & {
    permissions: string[];
};

const PermissionWrapper = ({
    outlet,
    permissions,
}: PermissionProtectedRouteProps) => {
    const { userHasPermission } = useAuthContext();

    if (userHasPermission(permissions)) {
        return outlet;
    } else {
        return (
            <Navigate
                to={{
                    pathname: "/permission-required",
                }}
            />
        );
    }
};

export default PermissionWrapper;
