import type { ThemeType } from "@t/client";
import { ThemeClasses } from "@utils/constants";
import type { ReactNode} from "react";
import { createContext, useContext, useMemo } from "react";

interface ThemeClassContextProps {
    children?: ReactNode;
}

interface ThemeContextType {
    themeClasses: ThemeType;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);
export const useTheme = () => useContext(ThemeContext);

const ThemeClassContextComponent = ({ children }: ThemeClassContextProps) => {
    const themeClasses: ThemeType = useMemo(() => ThemeClasses, []);

    return (
        <ThemeContext.Provider value={{ themeClasses }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeClassContextComponent };
