import type { ComboboxClasses } from "@t/client/theme/Combobox";
import { tw } from "../noop";

const comboboxClasses: ComboboxClasses = {
    colors: {
        default: tw("bg-gray-100 dark:bg-gray-700"),
        info: tw("bg-blue-500 text-white dark:bg-blue-400"),
        failure: tw("bg-red-500 text-white"),
        warning: tw(
            "bg-yellow-500 text-black dark:bg-yellow-400 dark:text-black",
        ),
        success: tw(
            "bg-green-500 text-black dark:bg-green-400 dark:text-black",
        ),
    },
};

export { comboboxClasses };
