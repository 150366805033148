import type { ThemeType } from "@t/client/theme";
import { accordionTheme } from "./accordion";
import { badgeClasses } from "./badge";
import { buttonClasses, buttonGroupClasses } from "./button";
import { comboboxClasses } from "./combobox";
import { dropdownClasses } from "./dropdown";
import { fieldClasses } from "./fields";
import { fileInputClasses } from "./fileInput";
import { floatingClasses } from "./floating";
import { helperTextClasses, utilClasses } from "./helperText";
import { labelClasses } from "./labels";
import { modalTheme } from "./modal";
import { radioGroupClasses } from "./radioGroup";
import { selectDropdownClasses } from "./selectDropdown";
import { toastClasses } from "./toast";

const ThemeClasses: ThemeType = {
    floating: floatingClasses,
    button: buttonClasses,
    buttonGroup: buttonGroupClasses,
    label: labelClasses,
    fields: fieldClasses,
    helperText: helperTextClasses,
    dropdown: dropdownClasses,
    combobox: comboboxClasses,
    toast: toastClasses,
    badge: badgeClasses,
    modal: modalTheme,
    accordion: accordionTheme,
    fileInput: fileInputClasses,
    selectDropdown: selectDropdownClasses,
    utilClasses: utilClasses,
    radioGroups: radioGroupClasses,
};

export default ThemeClasses;
