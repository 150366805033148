import add from "date-fns/add";
import parseISO from "date-fns/parseISO";

export function setLocalStorage(key: string, value: unknown): void {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // catch possible errors:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}

export function getLocalStorage<T>(key: string, initialValue: T): T {
    try {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
}

export function removeLocalStorage(key: string): void {
    try {
        window.localStorage.removeItem(key);
    } catch (e) {
        // catch possible errors:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}

export function setSessionStorage(key: string, value: unknown): void {
    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // catch possible errors:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}

export function getSessionStorage<T>(key: string, initialValue: T): T {
    try {
        const value = window.sessionStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
}

export function setCachedItem(
    key: string,
    value: unknown,
    expires: Date = add(new Date(), { hours: 1 }),
): void {
    setSessionStorage("cache_" + encodeURIComponent(key), { value, expires });
}

export function getCachedItem<T>(key: string): T | undefined {
    const cachedItem = getSessionStorage<
        | {
              value: T;
              expires: string;
          }
        | undefined
    >("cache_" + encodeURIComponent(key), undefined);

    if (cachedItem && parseISO(cachedItem.expires) > new Date()) {
        return cachedItem.value;
    } else {
        return undefined;
    }
}
