interface ICrudClaims {
    readonly All: string;
    readonly Create: string;
    readonly Read: string;
    readonly Update: string;
    readonly Delete: string;
}

interface ICompanyClaims {
    readonly ManageUsers: string;
    readonly UpdateRoles: string;
    readonly UpdateRolePermissions: string;
    readonly ManageConnections: string;
    readonly ManageGroups: string;
    readonly ManageSubscription: string;
    readonly All: string;
}

export type ClaimsType = {
    readonly Company: ICompanyClaims;

    readonly BaseMaterial: ICrudClaims;
    readonly CombinedMaterial: ICrudClaims;
    readonly Components: ICrudClaims;
    readonly Load: ICrudClaims;
    readonly Multipack: ICrudClaims;
    readonly LoadCatalogue: ICrudClaims;
    readonly CompletePackaging: ICrudClaims;
};

export const Claims: ClaimsType = {
    Company: {
        /** Permission claim to manage the company's subscription. Warn the user that the users with this permission can change the company's subscription. */
        ManageSubscription: "Company.ManageSubscription",

        /** Permission claim to accept or deny user requests to join a company */
        ManageUsers: "Company.ManageUserRequests",
        /**
         * Permission claim to manage a user's role inside the company. Only up to the current user's role.
         * So, if the user is an admin they will only be able to promote someone to admin also.
         */
        UpdateRoles: "Company.ManageUserRoles",

        /**
         * Permission claim to manage role's permissions inside the company.
         * Again, user should not be able to edit the role's permission ABOVE them.
         */
        UpdateRolePermissions: "Company.ManageRolePermissions",

        //Connections - This is for get incoming and outgoing, delete connection, and managing requests (requesting new, accept/decline, delete pending)
        ManageConnections: "Company.Connections",

        //Groups - All permissions related to permissions groups
        ManageGroups: "Company.Groups",

        /**
         * Permission claim to all the company permissions.
         */
        All: "Company.*",
    },

    BaseMaterial: {
        All: "BaseMaterial.*",
        Create: "BaseMaterial.Create",
        Read: "BaseMaterial.Read",
        Update: "BaseMaterial.Update",
        Delete: "BaseMaterial.Delete",
    },

    CombinedMaterial: {
        All: "Material.*",
        Create: "Material.Create",
        Read: "Material.Read",
        Update: "Material.Update",
        Delete: "Material.Delete",
    },

    Components: {
        All: "Components.*",
        Create: "Components.Create",
        Read: "Components.Read",
        Update: "Components.Update",
        Delete: "Components.Delete",
    },
    Load: {
        All: "Load.*",
        Create: "Load.Create",
        Read: "Load.Read",
        Update: "Load.Update",
        Delete: "Load.Delete",
    },

    LoadCatalogue: {
        All: "LoadCatalogue.*",
        Create: "LoadCatalogue.Create",
        Read: "LoadCatalogue.Read",
        Update: "LoadCatalogue.Update",
        Delete: "LoadCatalogue.Delete",
    },

    Multipack: {
        All: "Multipack.*",
        Create: "Multipack.Create",
        Read: "Multipack.Read",
        Update: "Multipack.Update",
        Delete: "Multipack.Delete",
    },

    CompletePackaging: {
        All: "CompletePackaging.*",
        Create: "CompletePackaging.Create",
        Read: "CompletePackaging.Read",
        Update: "CompletePackaging.Update",
        Delete: "CompletePackaging.Delete",
    },
};
