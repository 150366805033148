import type { DropdownClasses } from "@t/client/theme/Dropdown";
import { tw } from "../noop";

const dropdownClasses: DropdownClasses = {
    items: {
        active: {
            default: tw("bg-gray-100 dark:bg-gray-900"),
            info: tw("bg-blue-500 text-black dark:bg-blue-400"),
            failure: tw("bg-red-500 text-white"),
            warning: tw("bg-yellow-500 text-black dark:bg-yellow-400"),
            success: tw("bg-green-500 text-black dark:bg-green-400"),
        },
    },
};

export { dropdownClasses };
